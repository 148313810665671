import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { TableCell as MuiTableCell } from '@mui/material';
import { flexRender } from '@tanstack/react-table';
import styled from '@emotion/styled';
import { useMemo } from 'react';
import TableHeaderCellRenderer from './TableHeaderCellRenderer';
// TODO: make base cell styled component and use in both header and body cells as base
const TableHeaderCellStyled = styled(MuiTableCell)(() => ({
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    // padding: '0 12px',
    overflow: 'hidden',
}));
const TableHeaderCell = ({ className = undefined, tableApi, header, }) => {
    var _a, _b;
    const isCustomRender = useMemo(() => typeof header.column.columnDef.header !== 'string', [header]);
    return (_jsx(TableHeaderCellStyled, { className: className, component: 'div', colSpan: header.colSpan, align: ((_a = header.column.columnDef.meta) === null || _a === void 0 ? void 0 : _a.headerAlign) || 'left', onClick: header.column.getToggleSortingHandler(), style: {
            flex: ((_b = header.column.columnDef.meta) === null || _b === void 0 ? void 0 : _b.flex) || 1,
        }, children: !header.isPlaceholder && (_jsxs(_Fragment, { children: [isCustomRender && (flexRender(header.column.columnDef.header, header.getContext())), !isCustomRender && (_jsx(TableHeaderCellRenderer, { tableApi: tableApi, header: header }))] })) }, header.id));
};
export default TableHeaderCell;
