var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
const tableRendererWrapper = (Component) => function TableRenderer(_a) {
    var _b;
    var { row } = _a, rest = __rest(_a, ["row"]);
    const cellParams = {
        value: rest.getValue(),
        valueFormatted: rest.getValue(),
        rowId: Number(row.id),
        rowData: row.original,
        isSelected: row.getIsSelected(),
        isExpanded: row.getIsExpanded(),
        handleExpand: ((_b = rest.column.columnDef.meta) === null || _b === void 0 ? void 0 : _b.isExpandTrigger) ? row.toggleExpanded : undefined,
    };
    return (_jsx(Component, { cellParams: cellParams }));
};
export default tableRendererWrapper;
